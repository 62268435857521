import { Route } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import { Builder } from "features/builder";
import { CourseTopPage } from "features/course/top";
import { ProgramGamePage } from "features/courses/program/game";
import { AlgorithmGamePage } from "features/courses/algorithm/game";
import { ProgramLevelMapPage } from "features/courses/program/stage";
import { GroupWorkGamePage } from "features/courses/group_work/game";
import { AlgorithmLevelMapPage } from "features/courses/algorithm/stage";
import { GroupWorkLevelMapPage } from "features/courses/group_work/stage";
import { NishishinjukuGamePage } from "features/courses/nishishinjuku/game";
import { TakushokuBasicGamePage } from "features/courses/takushoku_basic/game";
import { NishishinjukuLevelMapPage } from "features/courses/nishishinjuku/stage";
import { TakushokuAdvanceGamePage } from "features/courses/takushoku_advance/game";
import { Recognition } from "features/courses/algorithm_advanced/game/recognition";
import { TakushokuBasicLevelMapPage } from "features/courses/takushoku_basic/stage";
import { AlgorithmAdvancedGamePage } from "features/courses/algorithm_advanced/game";
import { AlgorithmTangibleGamePage } from "features/courses/algorithm_tangible/game";
import { TakushokuAdvanceLevelMapPage } from "features/courses/takushoku_advance/stage";
import { AlgorithmAdvancedLevelMapPage } from "features/courses/algorithm_advanced/stage";
import { AlgorithmTangibleLevelMapPage } from "features/courses/algorithm_tangible/stage";
import { OldCourseTopPage } from "./OldTop";

const CourseTop = () => {
  const appConfig = useSelector((state: RootState) => state.config.appConfig);
  return <OldCourseTopPage />;
};

export const CoursePageRoutes = (
  <>
    <Route index element={<CourseTop />} />
    <Route path="algorithm">
      <Route path="stage/:stageId" element={<AlgorithmLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<AlgorithmGamePage />}
      />
    </Route>
    <Route path="algorithm_tangible">
      <Route
        path="stage/:stageId"
        element={<AlgorithmTangibleLevelMapPage />}
      />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<AlgorithmTangibleGamePage />}
      />
    </Route>
    <Route path="program">
      <Route path="stage/:stageId" element={<ProgramLevelMapPage />} />
      <Route path="stage/:stageId/step/:stepId" element={<ProgramGamePage />} />
    </Route>
    <Route path="algorithm_advanced">
      <Route path="recognition" element={<Recognition />} />
      <Route
        path="stage/:stageId"
        element={<AlgorithmAdvancedLevelMapPage />}
      />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<AlgorithmAdvancedGamePage />}
      />
    </Route>
    <Route path="group_work">
      <Route path="stage/:stageId" element={<GroupWorkLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<GroupWorkGamePage />}
      />
    </Route>
    <Route path="builder">
      <Route path="canvas/:id" element={<Builder />} />
    </Route>
    <Route path="nishishinjuku">
      <Route path="stage/:stageId" element={<NishishinjukuLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<NishishinjukuGamePage />}
      />
    </Route>
    <Route path="takushoku_basic">
      <Route path="stage/:stageId" element={<TakushokuBasicLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<TakushokuBasicGamePage />}
      />
    </Route>
    <Route path="takushoku_advance">
      <Route path="stage/:stageId" element={<TakushokuAdvanceLevelMapPage />} />
      <Route
        path="stage/:stageId/step/:stepId"
        element={<TakushokuAdvanceGamePage />}
      />
    </Route>
  </>
);
