import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "app/store";
import { fetchAuthToken } from "app/Auth";
import Constants from "common/constant";
import { client } from "common/apiClient";
import { Course } from "./types";

export const getCourseMessages = createAsyncThunk(
  "courses/getCourseMessages",
  async () => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get("/v1/course/message");
    return response.data;
  }
);

export const getCourseConfig = createAsyncThunk(
  "courses/getCourseConfig",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/course/config/${uid}`
    );
    return response.data;
  }
);

export const updateCourseConfig = createAsyncThunk(
  "courses/updateCourseConfig",
  async ({ mainUid, type }: { mainUid: string; type: string }, thunkAPI) => {
    const { courses } = thunkAPI.getState() as RootState;
    const token = await fetchAuthToken();
    await client({ token: token }).put(`/v1/course/config/${mainUid}`, {
      [type]: courses.config[type],
    });
    return;
  }
);

export const getCourses = createAsyncThunk(
  "courses/getCourses",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/course/progress/${uid}`
    );
    return response.data;
  }
);

export const getCourseLevelClearHistory = async ({
  course_id,
  uid,
  stageId,
  stepId,
}: {
  course_id: number;
  uid: string;
  stageId: number;
  stepId: number;
}) => {
  const token = await fetchAuthToken();
  const response = await client({ token: token }).get(
    `/v1/course/${course_id}/history/${uid}/stage/${stageId}/step/${stepId}`
  );
  return response.data;
};

export const allStageClearAnimation = async ({
  course_id,
  uid,
}: {
  course_id: number;
  uid: string;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/course/${course_id}/animation/${uid}/all`
  );
};

export const levelClearAnimation = async ({
  course_id,
  uid,
  stageId,
  stepId,
}: {
  course_id: number;
  uid: string;
  stageId: number;
  stepId: number;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/course/${course_id}/animation/${uid}/stage/${stageId}/step/${stepId}`
  );
};

export const updateLevelTips = async ({
  course_id,
  uid,
  stageId,
  stepId,
}: {
  course_id: number;
  uid: string;
  stageId: number;
  stepId: number;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/course/${course_id}/tips/${uid}/stage/${stageId}/step/${stepId}`
  );
};

export const updateLevelProgresses = async ({
  course_id,
  course,
  uid,
  stageId,
  stepId,
  totalFinished,
  answer,
  finishedAt,
  parameter,
}: {
  course_id: number;
  course: Course;
  uid: string;
  stageId: number;
  stepId: number;
  totalFinished: number;
  answer: string;
  finishedAt: string;
  parameter?: any;
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).put(
    `/v1/course/${course_id}/progress/${uid}/stage/${stageId}/step/${stepId}`,
    {
      total_finished: totalFinished,
      course,
      answer,
      finished_at: finishedAt,
      parameter,
    }
  );
};

export const updateLevelAnswer = async ({
  course_id,
  uid,
  stageId,
  stepId,
  answer,
  isFinished,
  answerAt,
  parameter,
}: {
  course_id: number;
  uid: string;
  stageId: number;
  stepId: number;
  answer: string;
  isFinished: boolean;
  answerAt: string;
  parameter?: any;
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).put(
    `/v1/course/${course_id}/progress/${uid}/stage/${stageId}/step/${stepId}/answer`,
    {
      answer,
      is_finished: isFinished,
      answer_at: answerAt,
      parameter,
    }
  );
};

export const getCourseProgresses = createAsyncThunk(
  "courses/getCourseProgresses",
  async ({ uid }: { uid: string }) => {
    const token = await fetchAuthToken();
    const response = await client({ token: token }).get(
      `/v1/progresses/course/${uid}`
    );
    return response.data.data;
  }
);

export const allAlgorithmStageClearAnimation = async ({
  uid,
}: {
  uid: string;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/progresses/algorithm/${uid}/animation/all`
  );
};

export const algorithmLevelClearAnimation = async ({
  uid,
  stageId,
  stepId,
}: {
  uid: string;
  stageId: number;
  stepId: number;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/progresses/algorithm/${uid}/animation/stage/${stageId}/step/${stepId}`
  );
};

export const updateAlgorithmLevelTips = async ({
  uid,
  stageId,
  stepId,
}: {
  uid: string;
  stageId: number;
  stepId: number;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/progresses/algorithm/${uid}/tips/stage/${stageId}/step/${stepId}`
  );
};

export const updateAlgorithmLevelProgresses = async ({
  uid,
  stageId,
  stepId,
  totalFinished,
  answer,
  finishedAt,
}: {
  uid: string;
  stageId: number;
  stepId: number;
  totalFinished: number;
  answer: string;
  finishedAt: string;
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).put(`/v1/progresses/algorithm/${uid}/stage/${stageId}/step/${stepId}`, {
    total_finished: totalFinished,
    answer,
    finished_at: finishedAt,
  });
};

export const updateAlgorithmLevelAnswer = async ({
  uid,
  stageId,
  stepId,
  answer,
}: {
  uid: string;
  stageId: number;
  stepId: number;
  answer: string;
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).put(
    `/v1/progresses/algorithm/${uid}/stage/${stageId}/step/${stepId}/answer`,
    {
      answer,
    }
  );
};

export const allProgramStageClearAnimation = async ({
  uid,
}: {
  uid: string;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/progresses/program/${uid}/animation/all`
  );
};

export const programLevelClearAnimation = async ({
  uid,
  stageId,
  stepId,
}: {
  uid: string;
  stageId: number;
  stepId: number;
}) => {
  const token = await fetchAuthToken();
  return client({ token: token }).put(
    `/v1/progresses/program/${uid}/animation/stage/${stageId}/step/${stepId}`
  );
};

export const updateProgramLevelProgresses = async ({
  uid,
  stageId,
  stepId,
  totalFinished,
  answer,
  finishedAt,
}: {
  uid: string;
  stageId: number;
  stepId: number;
  totalFinished: number;
  answer: string;
  finishedAt: string;
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).put(`/v1/progresses/program/${uid}/stage/${stageId}/step/${stepId}`, {
    total_finished: totalFinished,
    answer,
    finished_at: finishedAt,
  });
};

export const updateProgramLevelAnswer = async ({
  uid,
  stageId,
  stepId,
  answer,
}: {
  uid: string;
  stageId: number;
  stepId: number;
  answer: string;
}) => {
  const token = await fetchAuthToken();
  return client({
    token: token,
  }).put(
    `/v1/progresses/program/${uid}/stage/${stageId}/step/${stepId}/answer`,
    {
      answer,
    }
  );
};

export const handleImageAnalysis = async ({
  data,
}: {
  data: string | Blob | ArrayBuffer;
}) => {
  return axios.post(
    `${Constants.azureCVEndpoint}/computervision/imageanalysis:analyze?features=read&model-version=latest&language=ja&gender-neutral-caption=false&api-version=2024-02-01`,
    data,
    {
      headers: {
        "Ocp-Apim-Subscription-Key": Constants.azureCVKey,
        "Content-Type": "application/octet-stream",
      },
      timeout: 3000,
    }
  );
};
