import { TileSetMapType, CHARACTER_DIRECTION } from "common/model";

const landscapeLayer1 = [
  [23, 23, 23, 23, 23],
  [31, 31, 31, 31, 23],
  [31, 26, 26, 22, 22],
  [31, 31, 31, 31, 23],
  [23, 23, 23, 31, 23],
];
const landscapeLayer2 = [
  [42, 0, 36, 0, 35],
  [0, 0, 0, 0, 0],
  [0, 22, 13, 0, 8],
  [0, 0, 0, 0, 0],
  [0, 38, 0, 0, 35],
];
const landscapeLayer3 = [
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 1, 0, 0, 0],
  [0, 0, 0, 0, 0],
  [0, 0, 0, 0, 0],
];

const landscape = [landscapeLayer1, landscapeLayer2, landscapeLayer3];

const map: TileSetMapType = {
  character: {
    direction: CHARACTER_DIRECTION.CHARACTER_DIRECTION_MINUS_X,
  },
  landscape: landscape,
};

export default map;
