/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useSelector } from "react-redux";
import * as Blockly from "blockly";
import BlocklyJs from "blockly/javascript";
import { DisableTopBlocks } from "@blockly/disable-top-blocks";
import { RootState } from "app/store";
import { useScale } from "common/utils";
import { InitMsg } from "common/blockly";
import {
  InitColourBlock,
  InitControlsBlock,
  InitVariableBlock,
  useInitCharacterBlock,
  InitProceduresCallBlock,
} from "./blocks";
import { createBlocklyOptions } from "./utils";

export const useInitWorkspace = (
  setWorkspace: (workspace: Blockly.WorkspaceSvg) => void
) => {
  const { scale } = useScale();
  const InitCharacterBlock = useInitCharacterBlock();
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    InitMsg(config.locale);
  }, [config.locale]);

  useEffect(() => {
    InitCharacterBlock();
    InitControlsBlock();
    InitColourBlock();
    InitVariableBlock();
    InitProceduresCallBlock();

    let workspace: Blockly.WorkspaceSvg;
    workspace = Blockly.inject("blocklyDiv", createBlocklyOptions(scale));
    BlocklyJs.init(workspace);

    workspace.clearUndo();
    workspace.showContextMenu = () => {};

    workspace.addChangeListener(Blockly.Events.disableOrphans);
    const disableTopBlocksPlugin = new DisableTopBlocks();
    disableTopBlocksPlugin.init();

    BlocklyJs.addReservedWords("highlightBlock");
    setWorkspace(workspace);

    return () => {
      setWorkspace(null);
    };
  }, []);
};
