import { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import I18n from "i18n-js";
import { Howl } from "howler";
import * as Blockly from "blockly";
import { v4 as uuidv4 } from "uuid";
import BlocklyJs from "blockly/javascript";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { RootState } from "app/store";
import { CourseModel } from "app/types";
import { uploadImageFile } from "app/api";
import {
  sleep,
  useScale,
  btnDelay,
  usePlaySound,
  dataURLToBlob,
} from "common/utils";
import Constants from "common/constant";
import { BlockType } from "common/blockly";
import * as action from "common/model/animations";
import { actions } from "features/courses/coursesSlice";
import { Course, RunType } from "features/courses/types";
import { useGameBgm, useGameSound } from "features/courses/hook";
import { Score } from "features/courses/algorithm/game/scene/components";
import { StartBtn, ResetBtn, ZoomPanel } from "features/courses/components";
import { updateLevelAnswer, updateLevelProgresses } from "features/courses/api";
import StageMaps from "./maps";
import { Control } from "./Control";
import { GameScene } from "./GameScene";
import { ActionButton } from "./ActionButton";
import { ReadyStart, ResultPanel } from "./component";
import { TOP_OFFSET } from "../constant";
import { updateFlyout } from "../blockly";

export const MainScenePage = ({
  stage,
  step,
  isFinished,
  loading,
  reload,
  setReload,
  gameBgm,
  gameStart,
  setGameStart,
  animation,
  setAnimation,
  workspace,
  setLoading,
}: {
  stage: number;
  step: number;
  isFinished: boolean;
  loading: boolean;
  reload: boolean;
  setReload: (reload: boolean) => void;
  gameBgm: Howl | null;
  gameStart: boolean;
  setGameStart: (start: boolean) => void;
  animation: boolean;
  setAnimation: (animation: boolean) => void;
  workspace: Blockly.WorkspaceSvg;
  setLoading: (loading: boolean) => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gameSound = useGameSound();
  const { gameRunningBgm } = useGameBgm();
  const [fast, setFast] = useState(false);
  const [goal, setGoal] = useState(false);
  const [block, setBlock] = useState(false);
  const [count, setCount] = useState(false);
  const [ready, setReady] = useState(false);
  const [gemScore, setGemScore] = useState(0);
  const [totalGem, setTotalGem] = useState(0);
  const stepMap = StageMaps[stage - 1][step - 1];
  const [gameOver, setGameOver] = useState(false);
  const [forbidden, setForbidden] = useState(true);
  const [showResult, setShowResult] = useState(false);
  const [runType, setRunType] = useState(RunType.CLICK);
  const [scene, _setScene] = useState<Scene | null>(null);
  const sceneRef = useRef(scene);
  const setScene = (scene: Scene) => {
    sceneRef.current = scene;
    _setScene(scene);
  };
  const [goals, _setGoals] = useState<AbstractMesh[]>([]);
  const goalsRef = useRef(goals);
  const setGoals = (goals: AbstractMesh[]) => {
    goalsRef.current = goals;
    _setGoals(goals);
  };
  const [capture, setCapture] = useState<string | null>(null);
  const summary = useSelector(
    (state: RootState) =>
      state.courses.courses[Course.ALGORITHM_ADVANCED].summary
  );
  const course_id = useSelector(
    (state: RootState) =>
      state.courses.courses[Course.ALGORITHM_ADVANCED].course_id
  );
  const [characters, _setCharacters] = useState<AbstractMesh[]>([]);
  const charactersRef = useRef(characters);
  const setCharacters = (characters: AbstractMesh[]) => {
    charactersRef.current = characters;
    _setCharacters(characters);
  };
  const user = useSelector((state: RootState) => state.user.appUser);
  const config = useSelector((state: RootState) => state.config.userConfig);

  const requiredBlocks = ["character_motion_move"];
  const disableBlocks = ["controls_repeat_var_internal"];

  useEffect(() => {
    if (
      scene &&
      !loading &&
      workspace &&
      config.course_model === CourseModel.COMMAND
    ) {
      workspace
        .getTopBlocks(true)
        .filter((block) => block.isEnabled())
        .forEach((block) => {
          if (block.type.includes("character_event")) {
            const characterName = block.getFieldValue("CHARACTER");
            const character = charactersRef.current
              .filter((character) => character.name === characterName)
              .shift();
            if (character) {
              util.action.performAppearance(sceneRef.current, character);
            }
          }
        });
    }
  }, [workspace, scene, reload, loading]);

  useEffect(() => {
    let onWorkspaceChange: Function;
    if (workspace && config.course_model === CourseModel.COMMAND) {
      workspace.setScale(scale);

      setTimeout(() => {
        updateFlyout(workspace, TOP_OFFSET * scale);
      }, 300);

      onWorkspaceChange = (event: any) => {
        switch (event.type) {
          case Blockly.Events.VAR_CREATE:
          case Blockly.Events.VAR_RENAME:
          case Blockly.Events.VAR_DELETE:
            updateFlyout(workspace, TOP_OFFSET * scale);
            break;
          case Blockly.Events.BLOCK_CREATE:
            if (event.json.type === BlockType.CONTROLS_REPEAT_VAR_INTERNAL) {
              const block = workspace.getBlockById(event.blockId);
              block.setEnabled(false);
            } else if (event.json.type === BlockType.PROCEDURES_DEFNORETURN) {
              updateFlyout(workspace, TOP_OFFSET * scale);
            } else if (event.json.type === BlockType.VARIABLES_SET) {
              if (
                workspace.getBlocksByType(BlockType.VARIABLES_SET, false)
                  .length > 1
              ) {
                const varName = `${I18n.t("MSG_BLOCKLY_VARIABLES")}${
                  workspace.getBlocksByType(BlockType.VARIABLES_SET, false)
                    .length
                }`;
                const variable = workspace.createVariable(varName);
                const block = workspace.getBlockById(event.blockId);
                block.setFieldValue(variable.getId(), "VAR");
              }
              updateFlyout(workspace, TOP_OFFSET * scale);
            }
            break;
          case Blockly.Events.BLOCK_CHANGE:
            const block = workspace.getBlockById(event.blockId);
            if (block?.type.includes("character_event")) {
              setTimeout(() => {
                const currentCharacter = charactersRef.current
                  .filter((character) => character.name === event.oldValue)
                  .shift();
                if (currentCharacter) {
                  currentCharacter.setEnabled(false);
                }
                const character = charactersRef.current
                  .filter((character) => character.name === event.newValue)
                  .shift();
                if (character) {
                  util.action.performAppearance(sceneRef.current, character);
                }
              }, 500);
            } else if (block.type === BlockType.PROCEDURES_DEFNORETURN) {
              const funcName = block.getFieldValue("NAME");
              workspace
                .getBlocksByType(BlockType.PROCEDURES_CALLNORETURN, false)
                .filter(
                  (block) => block.getFieldValue("NAME") === event.oldValue
                )
                .forEach((block) => block.setFieldValue(funcName, "NAME"));
              updateFlyout(workspace, TOP_OFFSET * scale);
            } else if (block.type === BlockType.CONTROLS_REPEAT_VAR_INTERNAL) {
              block.setEnabled(false);
            }
            break;
          case Blockly.Events.BLOCK_DELETE:
            if (event.oldJson.type.includes("character_event")) {
              const eventBlock = new Blockly.BlockSvg(
                workspace,
                "character_event"
              );
              const blocklyFlyout = workspace.getFlyout() as Blockly.Flyout;
              eventBlock.initSvg();
              eventBlock.render();
              eventBlock.moveBy(
                blocklyFlyout.getWidth() / scale + 20 * scale,
                TOP_OFFSET + 20 * scale
              );
              const currentCharacter = charactersRef.current
                .filter(
                  (character) =>
                    character.name === event.oldJson.fields.CHARACTER
                )
                .shift();
              if (currentCharacter) {
                currentCharacter.setEnabled(false);
              }
              const character = charactersRef.current
                .filter((character) => character.name === "character_duck")
                .shift();
              if (character) {
                util.action.performAppearance(sceneRef.current, character);
              }
            } else if (
              event.oldJson.type === BlockType.PROCEDURES_DEFNORETURN
            ) {
              workspace
                .getBlocksByType(BlockType.PROCEDURES_CALLNORETURN, false)
                .filter(
                  (block) =>
                    block.getFieldValue("NAME") === event.oldJson.fields.NAME
                )
                .forEach((block) => block.dispose(false));
            } else if (event.oldJson.type === BlockType.VARIABLES_SET) {
              workspace
                .getBlocksByType(BlockType.VARIABLES_GET, false)
                .filter(
                  (block) =>
                    block.getFieldValue("VAR") === event.oldJson.fields.VAR.id
                )
                .forEach((block) => block.dispose(false));
            }

            workspace.trashcan.emptyContents();

            const toolbox = workspace.getToolbox() as Blockly.Toolbox;
            const flyout = toolbox.getFlyout() as Blockly.Flyout;
            if (flyout.isVisible()) {
              updateFlyout(workspace, TOP_OFFSET * scale);
            }
            break;
          default:
            break;
        }
      };

      workspace.addChangeListener(onWorkspaceChange);
    }
    return () => {
      if (onWorkspaceChange) {
        workspace.removeChangeListener(onWorkspaceChange);
      }
    };
  }, [scale, workspace]);

  useEffect(() => {
    if (gameOver) {
      const character = charactersRef.current
        .filter((character) => character.isEnabled())
        .shift();
      const goalFlag = goalsRef.current[0];
      const goalResult = character ? character.intersectsMesh(goalFlag) : false;
      setGoal(goalResult);

      const blockList = workspace
        .getAllBlocks(true)
        .filter(
          (block) =>
            block.isEnabled() &&
            block.type !== "math_number" &&
            block.type !== "colour_picker_internal"
        );
      const blockResult =
        requiredBlocks
          .map(
            (type) =>
              blockList.filter((block) => block.type === type).length > 0
          )
          .filter((r) => !r).length === 0;
      setBlock(blockResult);

      const forbiddenResult =
        disableBlocks
          .map(
            (type) =>
              blockList.filter((block) => block.type === type).length > 0
          )
          .filter((r) => r).length === 0;
      setForbidden(forbiddenResult);

      const countResult = blockList.length > 1;
      setCount(countResult);

      const xml = Blockly.Xml.workspaceToDom(workspace);
      const answer = Blockly.Xml.domToText(xml);

      const now = dayjs().tz("Asia/Tokyo").format("YYYY-MM-DDTHH:mm:ssZ");

      setShowResult(true);

      if (
        totalGem === gemScore &&
        blockResult &&
        countResult &&
        goalResult &&
        forbiddenResult
      ) {
        gameSound.levelclearSound();
        gameSound.levelclearCheersSound();
        if (isFinished) {
          dispatch(
            actions.updateLevelAnswer({
              course: Course.ALGORITHM_ADVANCED,
              stageId: stage,
              stepId: step,
              answer,
            })
          );
          updateLevelAnswer({
            course_id,
            uid: user.active.uid,
            stageId: stage,
            stepId: step,
            answer,
            answerAt: now,
            isFinished: true,
            parameter:
              config.course_model === CourseModel.TANGIBLE
                ? { type: "tangible", capture }
                : {},
          });
        } else {
          dispatch(
            actions.updateLevelProgresses({
              course: Course.ALGORITHM_ADVANCED,
              stageId: stage,
              stepId: step,
              totalFinished: summary.finished + 1,
              answer,
              finishedAt: now,
            })
          );
          updateLevelProgresses({
            course_id,
            course: Course.ALGORITHM_ADVANCED,
            uid: user.active.uid,
            stageId: stage,
            stepId: step,
            totalFinished: summary.finished + 1,
            answer,
            finishedAt: now,
            parameter:
              config.course_model === CourseModel.TANGIBLE
                ? { type: "tangible", capture }
                : {},
          });
        }
      } else {
        updateLevelAnswer({
          course_id,
          uid: user.active.uid,
          stageId: stage,
          stepId: step,
          answer,
          answerAt: now,
          isFinished: false,
          parameter:
            config.course_model === CourseModel.TANGIBLE
              ? { type: "tangible", capture }
              : {},
        });
        gameSound.failureSound();
      }
    }
  }, [gameOver]);

  useEffect(() => {
    gameSound.itemGetSound();
  }, [gemScore]);

  const handleCreateCharacter = () => {
    workspace
      .getTopBlocks(true)
      .filter(
        (block) => block.isEnabled() && block.type.includes("character_event")
      )
      .forEach((block) => {
        const characterName = block.getFieldValue("CHARACTER");
        charactersRef.current.forEach((character) => {
          if (character.isEnabled() && character.name !== characterName) {
            character.setEnabled(false);
          } else if (
            !character.isEnabled() &&
            character.name === characterName
          ) {
            util.action.performAppearance(sceneRef.current, character);
          }
        });
      });
  };

  const handlePlusGemScore = () => setGemScore((prevScore) => prevScore + 1);

  const highlightBlock = (id: string, opt_state: boolean) => {
    if (!scene.isDisposed) {
      if (workspace.getBlockById(id)) {
        workspace.getBlockById(id).setHighlighted(opt_state);
      }
    }
  };

  const util = {
    sleep: sleep,
    setGameOver: setGameOver,
    highlightBlock: highlightBlock,
    action: {
      checkBtnColor: action.checkBtnColor,
      performAppearance: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }

        if (!scene.isDisposed && !character.isEnabled()) {
          setAnimation(true);
          await action.performAppearance(scene, character, fast);
          gameSound.itemGetSound();
          setAnimation(false);
        }
      },
      performMove: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.moveSound();
          await action.performMove(
            scene,
            character,
            setGameOver,
            gameSound.moveSound,
            gameSound.fallingSound,
            fast
          );
        }
      },
      performJump: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.jumpSound();
          await action.performJump(scene, character, fast);
        }
      },
      performTurnToLeft: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.turnSound();
          await action.performTurnToLeft(scene, character, fast);
        }
      },
      performTurnToRight: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.turnSound();
          await action.performTurnToRight(scene, character, fast);
        }
      },
    },
  };

  const handleRun = () => {
    setReady(false);
    gameRunningBgm.play();
    workspace?.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
      block.setMovable(false);
      block.setEditable(false);
    });
    try {
      BlocklyJs.init(workspace);

      const code = BlocklyJs.workspaceToCode(workspace).replace(
        /function /g,
        "async $&"
      ); // safari maybe has some problems.

      const handleEvents = new Function(
        "scene",
        "character",
        "util",
        "gameSound",
        code
          ? code
          : `return () => {
              util.setGameOver(true);
            }
        `
      );

      let character: AbstractMesh;
      workspace
        .getTopBlocks(true)
        .filter((block) => block.isEnabled())
        .forEach((block) => {
          if (block.type.includes("character_event")) {
            const characterName = block.getFieldValue("CHARACTER");
            character = charactersRef.current
              .filter((character) => character.name === characterName)
              .shift();
          }
        });

      if (character) {
        handleEvents(sceneRef.current, character, util, gameSound)();
      } else {
        setGameOver(true);
      }
    } catch (error) {
      console.error(error);
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGameOver(true);
    }
  };

  const handleClickStart = () => {
    const character = charactersRef.current
      .filter((character) => character.isEnabled())
      .shift();
    if (character && !animation) {
      btnDelay(() => {
        setReady(true);
        gameBgm.stop();
        setGameStart(true);
        setRunType(RunType.CLICK);
        gameSound.startCountdownSound();
        setTimeout(() => handleRun(), 3000);
      });
    }
  };

  const handleStart = () => {
    const character = charactersRef.current
      .filter((character) => character.isEnabled())
      .shift();
    if (character && !animation) {
      setReady(true);
      gameBgm.stop();
      setRunType(RunType.RECOGNITION);
      gameSound.startCountdownSound();
      setTimeout(() => {
        setGameStart(true);

        const canvas = (document.getElementById(
          "local-stream-canvas"
        ) as unknown) as HTMLCanvasElement;
        const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
        const fileBlob = dataURLToBlob(dataUrl);

        const uuid = uuidv4();
        const filename = `tangible/${uuid}.png`;

        uploadImageFile({
          is_sync: false,
          filename,
          fileBlob,
        }).then(() => setCapture(`${Constants.assetHost}/${filename}`));

        handleRun();
      }, 3000);
    }
  };

  const handleGameOver = () => {
    // if (totalGem === gemScore && block && count && goal) {
    //   gameRunningBgm.stop();
    //   if (step < 7) {
    //     navigate(
    //       `/courses/${Course.ALGORITHM_ADVANCED}/stage/${stage}/step/${
    //         step + 1
    //       }`
    //     );
    //   } else {
    //     navigate(`/courses`);
    //   }
    // } else {
    //   handleReset();
    // }
  };

  const handleReset = () => {
    play();
    btnDelay(() => {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGoal(false);
      setGemScore(0);
      setBlock(false);
      setCount(false);
      setLoading(true);
      setForbidden(true);
      setGameOver(false);
      setReload(!reload);
      setGameStart(false);
      setShowResult(false);
      gameRunningBgm.pause();
      gameBgm.play();
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setMovable(true);
        block.setHighlighted(false);
        block.setEditable(true);
      });
    });
  };

  const handleBack = () => {
    play();
    btnDelay(() => {
      setShowResult(false);
      navigate(`/courses/${Course.ALGORITHM_ADVANCED}/stage/${stage}`);
    });
  };

  const handleFastForward = () => {
    setFast(!fast);
  };

  return (
    <>
      <ZoomPanel scene={sceneRef.current} />

      {config.course_model === CourseModel.TANGIBLE &&
        workspace &&
        scene &&
        characters.length > 0 && (
          <Control
            ready={ready}
            gameStart={gameStart}
            gameOver={gameOver}
            workspace={workspace}
            handleStart={handleStart}
            handleGameOver={handleGameOver}
            handleCreateCharacter={handleCreateCharacter}
          />
        )}

      <ReadyStart open={ready} />

      {gameOver && showResult && (
        <ResultPanel
          result={{ gem: totalGem === gemScore, block, forbidden, count, goal }}
          auto={runType === RunType.RECOGNITION}
          back={handleBack}
          reset={handleReset}
        />
      )}

      <div className="flex-col-view items-center w-full h-full pointer-events-none">
        <div className="flex-col-center w-full h-full pointer-events-none">
          <GameScene
            map={stepMap}
            reload={reload}
            handler={{
              setLoading,
              setScene,
              setCharacters,
              setGoals,
              setTotalGem,
              handlePlusGemScore,
            }}
          />

          <div
            style={{ height: 56 * scale }}
            className="flex-row-el absolute top-0"
          >
            <div
              className="flex-row-view h-[56px] origin-top"
              style={{ transform: `scale(${scale})` }}
            >
              <Score totalGem={totalGem} gemScore={gemScore} />
            </div>
          </div>
        </div>

        {!gameStart ? (
          <StartBtn
            scale={scale}
            disabled={
              charactersRef.current.filter((character) => character.isEnabled())
                .length === 0 || animation
            }
            start={handleClickStart}
          />
        ) : (
          <ResetBtn scale={scale} reset={handleReset} />
        )}

        <ActionButton fast={fast} handleFastForward={handleFastForward} />
      </div>
    </>
  );
};
