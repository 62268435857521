import Blockly from "blockly";
import {
  ExecutableBlock,
  WorkspaceBlockList,
  BlockType as TangibleBlockType,
} from "common/tangible";
import { theme } from "common/blockly";
import Constants from "common/constant";

export const createBlocklyOptions = (
  scale: number,
  toolbox?: Blockly.utils.toolbox.ToolboxDefinition
) => ({
  collapse: true,
  comments: false,
  css: true,
  disable: true,
  sounds: false,
  horizontalLayout: false,
  media: `${Constants.assetHost}/assets/blockly/game/`,
  move: {
    scrollbars: false,
    drag: true,
    wheel: false,
  },
  zoom: {
    startScale: scale,
  },
  oneBasedIndex: true,
  readOnly: false,
  rtl: false,
  toolbox: toolbox,
  toolboxPosition: "start",
  maxInstances: {
    character_event: 1,
  },
  trashcan: false,
  maxTrashcanContents: 32,
  renderer: "zelos",
  theme: theme,
});

export const generateWorkspaceBlock = (
  scale: number,
  blocks: ExecutableBlock[][],
  workspace: Blockly.WorkspaceSvg
) => {
  workspace.clear();
  try {
    if (blocks.length > 0) {
      const eventBlockList = blocks[0];
      const workspaceBlockList: WorkspaceBlockList[] = [];
      for (let j = 0; j < eventBlockList.length; j++) {
        const block = eventBlockList[j];
        if (
          block.type === TangibleBlockType.EVENT_START ||
          block.type ===
            TangibleBlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
        ) {
          let workspaceBlock: Blockly.BlockSvg;
          if (block.type === TangibleBlockType.EVENT_START) {
            workspaceBlock = new Blockly.BlockSvg(workspace, "character_event");
          } else {
            workspaceBlock = new Blockly.BlockSvg(
              workspace,
              block.type.replace("_start", "")
            );
          }

          workspaceBlock.initSvg();
          workspaceBlock.render();
          workspaceBlock.moveTo(
            new Blockly.utils.Coordinate(50 / scale, 170 / scale)
          );

          if (
            block.type ===
            TangibleBlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
          ) {
            workspaceBlock.getField("NAME")?.setValue(block.args[0]);
          }

          workspaceBlockList.push({ block, workspaceBlock });
        } else if (block.type.includes("end")) {
          workspaceBlockList.push({ block, workspaceBlock: null });
        } else {
          let workspaceBlock: Blockly.BlockSvg;
          if (block.type === TangibleBlockType.PROCEDURES_CALLNORETURN_CALL) {
            workspaceBlock = new Blockly.BlockSvg(
              workspace,
              block.type + `_${block.args[0]}`
            );
            workspaceBlock.getField("NAME")?.setValue(block.args[1]);
          } else if (block.type === TangibleBlockType.VARIABLES_SET) {
            workspaceBlock = new Blockly.BlockSvg(workspace, block.type);
            const variableSetBlock = workspace.getVariableById(
              workspaceBlock.getFieldValue("VAR")
            );
            variableSetBlock.name = block.args[0];
            if (isNaN(Number(block.args[1]))) {
              variableSetBlock.type = "Colour";
              const colourPickerBlock = new Blockly.BlockSvg(
                workspace,
                "colour_picker_internal"
              );
              colourPickerBlock.initSvg();
              colourPickerBlock.render();
              colourPickerBlock.getField("COLOUR")?.setValue(block.args[1]);
              workspaceBlock
                .getInput("VALUE")
                .connection.connect(colourPickerBlock.outputConnection);
            } else {
              variableSetBlock.type = "Number";
              const mathNumberBlock = new Blockly.BlockSvg(
                workspace,
                "math_number"
              );
              mathNumberBlock.initSvg();
              mathNumberBlock.render();
              mathNumberBlock.getField("NUM")?.setValue(block.args[1]);
              workspaceBlock
                .getInput("VALUE")
                .connection.connect(mathNumberBlock.outputConnection);
            }
            console.log(variableSetBlock);
          } else if (block.type.includes("var_internal")) {
            const variableSetBlock = workspace.getVariable(block.args[0]);

            const variableGetBlock = new Blockly.BlockSvg(
              workspace,
              TangibleBlockType.VARIABLES_GET
            );
            variableGetBlock.initSvg();
            variableGetBlock.render();
            (variableGetBlock.getField(
              "VAR"
            ) as Blockly.FieldVariable).variableTypes = [variableSetBlock.type];
            variableGetBlock.setFieldValue(variableSetBlock.getId(), "VAR");

            if (
              block.type === TangibleBlockType.CONTROLS_LOOP_VAR_INTERNAL_START
            ) {
              if (variableSetBlock.type === "Colour") {
                workspaceBlock = new Blockly.BlockSvg(
                  workspace,
                  TangibleBlockType.CONTROLS_WHILEUNTIL_COLOUR_VAR_INTERNAL_START.replace(
                    "_start",
                    ""
                  )
                );
                workspaceBlock
                  .getInput("COLOUR")
                  .connection.connect(variableGetBlock.outputConnection);
              } else {
                workspaceBlock = new Blockly.BlockSvg(
                  workspace,
                  TangibleBlockType.CONTROLS_REPEAT_VAR_INTERNAL_START.replace(
                    "_start",
                    ""
                  )
                );
                workspaceBlock
                  .getInput("TIMES")
                  .connection.connect(variableGetBlock.outputConnection);
              }
            } else if (
              block.type ===
              TangibleBlockType.CONTROLS_IF_COLOUR_VAR_INTERNAL_START
            ) {
              workspaceBlock = new Blockly.BlockSvg(
                workspace,
                block.type.replace("_start", "")
              );
              workspaceBlock
                .getInput("COLOUR")
                .connection.connect(variableGetBlock.outputConnection);
            }
          } else {
            workspaceBlock = new Blockly.BlockSvg(
              workspace,
              block.type.includes("start")
                ? block.type.replace("_start", "")
                : block.type
            );
            if (workspaceBlock.loadExtraState) {
              workspaceBlock.loadExtraState({ max: Number(block.args[0]) });
            }
            workspaceBlock.getField("TIMES")?.setValue(block.args[0]);
            if (workspaceBlock.getInput("COLOUR")) {
              const colourPickerBlock = new Blockly.BlockSvg(
                workspace,
                "colour_picker_internal"
              );
              colourPickerBlock.initSvg();
              colourPickerBlock.render();
              colourPickerBlock.getField("COLOUR")?.setValue(block.args[0]);
              workspaceBlock
                .getInput("COLOUR")
                .connection.connect(colourPickerBlock.outputConnection);
            }
          }

          workspaceBlock.initSvg();
          workspaceBlock.render();

          const previousBlock =
            workspaceBlockList[workspaceBlockList.length - 1];
          if (previousBlock.block.type.includes("start")) {
            previousBlock.workspaceBlock
              .getInput(
                previousBlock.block.type ===
                  TangibleBlockType.PROCEDURES_CALLNORETURN_DEFINITION_START
                  ? "STACK"
                  : "DO"
              )
              .connection.connect(workspaceBlock.previousConnection);
          } else if (previousBlock.block.type.includes("end")) {
            const parentBlock = workspaceBlockList
              .filter((item) => item.block.id === previousBlock.block.parentId)
              .shift();
            if (parentBlock && parentBlock.workspaceBlock.nextConnection) {
              parentBlock.workspaceBlock.nextConnection.connect(
                workspaceBlock.previousConnection
              );
            }
          } else {
            if (previousBlock.workspaceBlock.nextConnection) {
              previousBlock.workspaceBlock.nextConnection.connect(
                workspaceBlock.previousConnection
              );
            }
          }

          workspaceBlock.setMovable(false);
          workspaceBlock.setEditable(false);
          workspaceBlockList.push({ block, workspaceBlock });
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
};
