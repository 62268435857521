import { useInitWorkspace } from "./useInitWorkspace";

export const BlocklyWorkspace = ({
  setWorkspace,
}: {
  setWorkspace: (workspace: any) => void;
}) => {
  useInitWorkspace(setWorkspace);
  return (
    <div className="flex-row-el flex-1 w-full h-full p-2">
      <div className="flex-col-el flex-1">
        <div id="blocklyDiv" className="flex-col-el flex-1 w-full h-full" />
      </div>
    </div>
  );
};
