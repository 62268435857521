import Constants from "common/constant";
import { useScale } from "common/utils";

export const LevelClearStamp = () => {
  const { scale } = useScale();
  return (
    <div
      style={{
        top: 116 * scale,
        right: 50 * scale,
      }}
      className="clear-stamp-animation !absolute pointer-events-none"
    >
      <div
        className="flex-col-view w-[245px] h-[245px] object-contain origin-top-right"
        style={{
          transform: `scale(${scale})`,
        }}
      >
        <img
          alt="レベルクリアスタンプ"
          src={`${Constants.assetHost}/assets/images/stamp_cleared.png`}
          className="w-full h-full"
        />
      </div>
    </div>
  );
};
