import { EntityState } from "@reduxjs/toolkit";

export enum RunType {
  CLICK = "click",
  RECOGNITION = "recognition",
}

export enum Course {
  ALGORITHM = "algorithm",
  PROGRAM = "program",
  ALGORITHM_TANGIBLE = "algorithm_tangible",
  ALGORITHM_ADVANCED = "algorithm_advanced",
  GROUP_WORK = "group_work",
  NISHISHINJUKU = "nishishinjuku",
  TAKUSHOKU_BASIC = "takushoku_basic",
  TAKUSHOKU_ADVANCE = "takushoku_advance",
  BUILDER = "builder",
  LEVEL_MAP = "level_map",
}

export interface AlgorithmSummary {
  id: number;
  uid: string;
  finished: number;
  total: number;
  all_stage_clear_animation: boolean;
}

export interface AlgorithmProgress {
  id: number;
  stage: {
    id: number;
    uid: string;
    stage_id: number;
    finished: number;
    total: number;
    animation: number;
  };
  level: {
    id: number;
    uid: string;
    stage_id: number;
    level_id: number;
    answer: string;
    is_finished: boolean;
    finished_at: string;
    tips: boolean;
  }[];
}

export interface ProgramSummary {
  id: number;
  uid: string;
  finished: number;
  total: number;
  all_stage_clear_animation: boolean;
}

export interface ProgramProgress {
  id: number;
  stage: {
    id: number;
    uid: string;
    stage_id: number;
    finished: number;
    total: number;
    animation: number;
  };
  level: {
    id: number;
    uid: string;
    stage_id: number;
    level_id: number;
    answer: string;
    is_finished: boolean;
    finished_at: string;
    tips: boolean;
  }[];
}

export interface CourseMessageContent {
  name: string;
  description: string;
  tips?: string[];
  level: {
    name: string;
    description: string;
    purpose: string[];
    goal: string;
    blocks?: string[];
    tips?: string[];
    hint: {
      keypoints: string[];
      title: string;
      description: string[];
      blocks: string[];
    };
  }[];
}

export interface CourseMessage {
  id: number;
  ja: CourseMessageContent;
  ja_kanji: CourseMessageContent;
}

export interface CourseSummary {
  id: number;
  type: string;
  uid: string;
  finished: number;
  total: number;
  all_stage_clear_animation: boolean;
}

export interface CourseProgress {
  stage: {
    id: number;
    uid: string;
    course_id: number;
    stage_id: number;
    finished: number;
    total: number;
    animation: number;
  };
  level: {
    id: number;
    uid: string;
    stage_progress_id: number;
    stage_id: number;
    level_id: number;
    answer: string;
    is_finished: boolean;
    finished_at: string;
    tips: boolean;
  }[];
}

export interface CourseInitialStateType {
  config: {
    tangible: {
      deviceId: string;
    };
  };
  message: { [course: string]: CourseMessage };
  courses: {
    [course: string]: {
      course_id: number;
      summary: CourseSummary;
      progress: CourseProgress[];
    };
  };
  progresses: {
    algorithm: {
      summary: AlgorithmSummary;
      progress: EntityState<AlgorithmProgress>;
    };
    program: {
      summary: ProgramSummary;
      progress: EntityState<ProgramProgress>;
    };
  };
}
