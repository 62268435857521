import { useState, useEffect } from "react";
import { Dialog } from "@mui/material";
import { useScale } from "common/utils";
import Constants from "common/constant";
import { CloseBtn, FadeTransition } from "common/elements";
import { Decoration, Background } from "features/install/assets";

export const RecognitionRunFailed = ({ reset }: { reset: () => void }) => {
  const { scale } = useScale();
  const [disable, setDisable] = useState(false);

  useEffect(() => {
    setDisable(false);
  }, []);

  const handleReset = () => {
    if (!disable) {
      setDisable(true);
      reset();
    }
  };

  return (
    <Dialog
      open
      fullScreen
      TransitionComponent={FadeTransition}
      transitionDuration={{ enter: 1000, exit: 500 }}
      componentsProps={{
        backdrop: { style: { backgroundColor: "transparent" } },
      }}
      sx={{ zIndex: 10001, backgroundColor: "transparent" }}
      PaperProps={{ style: { backgroundColor: "transparent" } }}
    >
      <div className="flex-col-el flex-center absolute w-full h-full bg-textcolor/black/20">
        <div
          className="flex-col-center"
          style={{
            width: 580 * scale,
            height: 140 * scale,
            filter: "drop-shadow(0px 0px 30px rgba(63, 74, 97, 0.35))",
          }}
        >
          <div
            className="flex-col-center w-[580px] h-[140px] cursor-pointer"
            style={{ transform: `scale(${scale})` }}
          >
            <div
              onClick={handleReset}
              className="flex-col-center !absolute top-[16px] right-[40px] pointer-events-auto"
            >
              <CloseBtn />
            </div>

            <div className="absolute block z-[-1]">
              <div>
                <Background />
              </div>

              <div className="absolute block top-[32px] left-[-40px]">
                <Decoration />
              </div>
            </div>

            <div className="flex flex-row items-center">
              <div
                className="flex-col-center bg-white rounded-full w-[96px] h-[96px] mr-4"
                style={{
                  boxShadow:
                    "-1px 1px 1px rgba(63, 74, 97, 0.15), 1px 1px 3px rgba(63, 74, 97, 0.25)",
                }}
              >
                <div className="flex-col-center w-[85%] h-[85%] rounded-full overflow-hidden">
                  <img
                    alt="アプリケーションのアイコン"
                    src={`${Constants.assetHost}/assets/images/app-icon.png`}
                    className="w-full h-full object-contain"
                  />
                </div>
              </div>

              <div className="flex flex-col flex-1">
                <div className="flex flex-col">
                  <p className="text text-textcolor/black text-left !text-[24px]">
                    {"ざんねん！やりなおしてね。"}
                  </p>
                  <p className="text text-green/100 text-left !text-[18px]">
                    {"（スタート）を　はずしてください"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};