import { useRef, useMemo, useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Blockly from "blockly";
import { Howl } from "howler";
import utc from "dayjs/plugin/utc";
import throttle from "lodash-es/throttle";
import timezone from "dayjs/plugin/timezone";
import { Scene } from "@babylonjs/core/scene";
import { RootState } from "app/store";
import { useCourseRecord } from "app/hook";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { Dialog } from "common/blockly";
import { Loading } from "common/elements";
import {
  ZoomPanel,
  GameSceneTitle,
  BackToCourseTop,
  GameBgContainer,
} from "features/courses/components";
import { ConfigContext } from "features/top";
import { BillingType } from "features/user/types";
import { LevelClearStamp } from "features/courses/assets";
import { TipsPage } from "features/courses/algorithm/game/tips";
import { GuidePage } from "features/courses/algorithm/game/Guide";
import { MainScenePage } from "features/courses/algorithm/game/scene";
import { Course, CourseMessageContent } from "features/courses/types";
import { BlocklyWorkspace } from "features/courses/algorithm/game/blockly";
import { selectProgressAlgorithmById } from "features/courses/coursesSlice";

const GamePage = ({ stage, step }: { stage: number; step: number }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const TOP_OFFSET = 120 + 32;
  const COMMAND_PANEL_HEIGHT = 82;
  const navigate = useNavigate();
  const { width, scale } = useScale();
  const [event, setEvent] = useState(null);
  const [isFirst, setIsFirst] = useState(true);
  const [loading, setLoading] = useState(true);
  const [reload, _setReload] = useState(false);
  const reloadRef = useRef(reload);
  const setReload = (reload: boolean) => {
    reloadRef.current = reload;
    _setReload(reload);
  };
  const configContext = useContext(ConfigContext);
  const [gameStart, setGameStart] = useState(false);
  const [scene, setScene] = useState<Scene | null>(null);
  const [gameBgm, setGameBgm] = useState<Howl | null>(null);
  const [blocklyFlyoutWidth, setBlocklyFlyoutWidth] = useState(0);
  const [blocklyFlyoutHeight, setBlocklyFlyoutHeight] = useState(0);
  const handleTrack = useCourseRecord(Course.ALGORITHM, stage, step);
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg>(null);
  const user = useSelector((state: RootState) => state.user.appUser);
  const config = useSelector((state: RootState) => state.config.userConfig);
  const courseDisplay = useSelector(
    (state: RootState) => state.courses.message[Course.ALGORITHM][stage - 1]
  );
  const { stage: stageProgress, level } = useSelector((state: RootState) =>
    selectProgressAlgorithmById(state, stage)
  );
  const { tips, is_finished, finished_at } = level[step - 1];

  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
    } else {
      navigate("/courses");
    }
  }, [user]);

  const handleResize = useMemo(
    () =>
      throttle(
        () => {
          setReload(!reloadRef.current);
        },
        1000,
        { leading: false, trailing: true }
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(() => {
    if (!isFirst) {
      handleResize();
    }
  }, [scale]);

  useEffect(() => {
    handleTrack(user.main.billing_type === BillingType.EDUCATION);
    return () => {
      handleTrack(false);
    };
  }, []);

  useEffect(() => {
    console.log(`stage: ${stage}, step: ${step}`);
    if ("AudioContext" in window) {
      const bgmSound = new Howl({
        mute: !config.bgm.enable,
        volume: config.bgm.volume,
        autoplay: false,
        loop: true,
        src: [`${Constants.assetHost}/assets/bgm/bgm_game.mp3`],
      });
      setGameBgm(bgmSound);
    }
    // if (!is_finished && stageProgress.finished + 1 !== step) {
    //   navigate(`/courses/${Course.ALGORITHM}/stage/${stage}`);
    // }
  }, []);

  useEffect(() => {
    if (gameBgm) {
      gameBgm.mute(!config.bgm.enable);
      gameBgm.volume(config.bgm.volume);
    }
  }, [config.bgm]);

  useEffect(() => {
    if (configContext.bgmSound && gameBgm) {
      gameBgm.play();
      configContext.bgmSound.stop();
    }
    return () => {
      if (configContext.bgmSound && gameBgm) {
        gameBgm.stop();
        configContext.bgmSound.play();
      }
    };
  }, [configContext.bgmSound, gameBgm]);

  useEffect(() => {
    if (workspace) {
      const flyout = workspace.getFlyout() as Blockly.Flyout;
      setBlocklyFlyoutWidth(flyout.getWidth() + 30 * scale);
      setBlocklyFlyoutHeight(flyout.getHeight());
    }
  }, [workspace, scale]);

  return (
    <GameBgContainer workspace={workspace}>
      <Dialog workspace={workspace} />

      <img
        alt="ゲームの背景"
        src={`${Constants.assetHost}/assets/images/bg_scene.jpg`}
        className="absolute top-0 left-0 w-full h-full"
      />

      <div className="flex-col-view items-start w-full h-full z-[1] opacity-100 pointer-events-none">
        <div
          className="flex-col-el flex-center w-full z-10"
          style={{ height: 80 * scale }}
        >
          <GameSceneTitle
            level={step}
            title={
              courseDisplay
                ? (courseDisplay[config.locale] as CourseMessageContent)?.level[
                    step - 1
                  ].name.replace("\n", "")
                : ""
            }
          />
        </div>

        <div
          className="flex-col-view !flex-1 w-full h-full pointer-events-none"
          style={{
            marginTop: 32 * scale,
          }}
        >
          {blocklyFlyoutWidth && (
            <div
              id="game-main-scene-canvas"
              className="flex-col-view !absolute h-full rounded-[10px] pointer-events-none"
              style={{
                left: blocklyFlyoutWidth + 100 * scale,
                width: width - blocklyFlyoutWidth - 100 * scale,
                padding: `0px ${10 * scale}px ${10 * scale}px`,
              }}
            >
              <MainScenePage
                stage={stage}
                step={step}
                reload={reload}
                setReload={setReload}
                isFinished={is_finished}
                scene={scene}
                gameBgm={gameBgm}
                gameStart={gameStart}
                setGameStart={setGameStart}
                setScene={setScene}
                workspace={workspace}
                flyoutWidth={blocklyFlyoutWidth}
                blocklyTopOffset={TOP_OFFSET + COMMAND_PANEL_HEIGHT}
                event={event}
                setLoading={setLoading}
              />
            </div>
          )}
        </div>

        <div
          className="flex-col-view !absolute top-0 left-0 !flex-1 h-full w-full rounded-[10px] z-20 pointer-events-none"
          style={{
            padding: `0px ${10 * scale}px ${17 * scale}px`,
          }}
        >
          <BlocklyWorkspace
            gameStart={gameStart}
            scale={scale}
            stage={stage}
            step={step}
            topOffset={TOP_OFFSET}
            commandPanelHeight={COMMAND_PANEL_HEIGHT}
            workspace={workspace}
            setWorkspace={setWorkspace}
            flyoutWidth={blocklyFlyoutWidth}
            flyoutHeight={blocklyFlyoutHeight}
            setEvent={setEvent}
          />
        </div>
      </div>

      <ZoomPanel scene={scene} />

      <GuidePage
        courseDisplay={courseDisplay ? courseDisplay[config.locale] : ""}
        stage={stage}
        step={step}
      />

      {stage === 1 && step === 1 && !tips && !loading && (
        <TipsPage
          stage={stage}
          step={step}
          scene={scene}
          blocklyFlyoutWidth={blocklyFlyoutWidth}
          workspace={workspace}
        />
      )}

      {!loading && is_finished && (
        <LevelClearStamp
          scale={scale}
          date={dayjs(finished_at).tz("Asia/Tokyo").format("YYYY.M.DD")}
        />
      )}

      {(loading || !courseDisplay) && <Loading />}
    </GameBgContainer>
  );
};

export const AlgorithmGamePage = () => {
  const { stageId, stepId } = useParams<{
    stageId?: string;
    stepId?: string;
  }>();
  const step = Number(stepId);
  const stage = Number(stageId);
  return isNaN(stage) || isNaN(step) || stage > 6 || step > 7 ? (
    <BackToCourseTop />
  ) : (
    <GamePage stage={stage} step={step} />
  );
};
