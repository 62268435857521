import { useRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Blockly from "blockly";
import throttle from "lodash-es/throttle";
import { RootState } from "app/store";
import { Dialog } from "common/blockly";
import Constants from "common/constant";
import { useScale } from "common/utils";
import { useOpenCv } from "common/hook";
import { Loading } from "common/elements";
import {
  GameSceneTitle,
  BackToCourseTop,
  GameBgContainer,
} from "features/courses/components";
import { useInitGame } from "features/courses/hook";
import { Course, CourseMessageContent } from "features/courses/types";
import { GuidePage } from "./Guide";
import { MainScenePage } from "./scene";
import { BlocklyWorkspace } from "./blockly";
import { LevelClearStamp } from "./LevelClearStamp";

const GamePage = ({ stage, step }: { stage: number; step: number }) => {
  const { scale, width } = useScale();
  const [reload, _setReload] = useState(false);
  const reloadRef = useRef(reload);
  const setReload = (reload: boolean) => {
    reloadRef.current = reload;
    _setReload(reload);
  };
  const handleResize = useMemo(
    () =>
      throttle(
        () => {
          setReload(!reloadRef.current);
        },
        1000,
        { leading: false, trailing: true }
      ),
    []
  );
  const [opencv, setOpencv] = useState(false);
  const [loading, setLoading] = useState(true);
  const [gameStart, setGameStart] = useState(false);
  useOpenCv({ setInitialized: () => setOpencv(true) });
  const { gameBgm } = useInitGame(stage, step, handleResize);
  const courseMessage = useSelector(
    (state: RootState) =>
      state.courses.message[Course.ALGORITHM_TANGIBLE][stage - 1]
  );
  const [workspace, setWorkspace] = useState<Blockly.WorkspaceSvg>(null);
  const { level } = useSelector(
    (state: RootState) =>
      state.courses.courses[Course.ALGORITHM_TANGIBLE].progress[stage - 1]
  );
  const { is_finished } = level[step - 1];
  const config = useSelector((state: RootState) => state.config.userConfig);

  return (
    <GameBgContainer workspace={workspace}>
      <Dialog workspace={workspace} limitLength={10} />

      <img
        alt="ゲームの背景"
        draggable="false"
        src={`${Constants.assetHost}/assets/images/bg_scene.jpg`}
        className="absolute top-0 left-0 w-full h-full"
      />

      <div className="flex-col-el relative items-start w-full h-full z-[1] opacity-100 pointer-events-none">
        <div
          style={{ height: 80 * scale }}
          className="flex-col-el flex-center w-full z-10"
        >
          <GameSceneTitle
            level={step}
            title={
              courseMessage
                ? (courseMessage[config.locale] as CourseMessageContent).level[
                    step - 1
                  ].name.replace("\n", "")
                : ""
            }
          />
        </div>

        <div
          style={{ marginTop: 32 * scale }}
          className="flex-col-el relative flex-1 w-full h-full pointer-events-none"
        >
          <div
            id="game-main-scene-canvas"
            style={{ left: 0, width: width }}
            className="flex-col-el absolute left-0 h-full pointer-events-none"
          >
            <MainScenePage
              stage={stage}
              step={step}
              isFinished={is_finished}
              loading={loading}
              reload={reload}
              setReload={setReload}
              gameBgm={gameBgm}
              gameStart={gameStart}
              setGameStart={setGameStart}
              workspace={workspace}
              setLoading={setLoading}
            />
          </div>
        </div>

        <div className="flex-col-el absolute top-0 left-0 flex-1 h-full w-full z-20 pointer-events-none">
          <BlocklyWorkspace setWorkspace={setWorkspace} />
        </div>
      </div>

      {!gameStart && (
        <GuidePage
          courseDisplay={courseMessage ? courseMessage[config.locale] : ""}
          stage={stage}
          step={step}
        />
      )}

      {!loading && is_finished && !gameStart && <LevelClearStamp />}

      {(loading || !opencv) && <Loading />}
    </GameBgContainer>
  );
};

export const AlgorithmTangibleGamePage = () => {
  const { stageId, stepId } = useParams<{
    stageId?: string;
    stepId?: string;
  }>();
  const step = Number(stepId);
  const stage = Number(stageId);
  return isNaN(stage) || isNaN(step) || stage > 6 || step > 7 ? (
    <BackToCourseTop />
  ) : (
    <GamePage stage={stage} step={step} />
  );
};
