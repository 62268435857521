import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { Howl } from "howler";
import * as Blockly from "blockly";
import { v4 as uuidv4 } from "uuid";
import BlocklyJs from "blockly/javascript";
import { Scene } from "@babylonjs/core/scene";
import { AbstractMesh } from "@babylonjs/core/Meshes/abstractMesh";
import { RootState } from "app/store";
import { CourseModel } from "app/types";
import { uploadImageFile } from "app/api";
import {
  sleep,
  useScale,
  btnDelay,
  usePlaySound,
  dataURLToBlob,
} from "common/utils";
import Constants from "common/constant";
import { actions } from "features/courses/coursesSlice";
import { Course, RunType } from "features/courses/types";
import { useGameBgm, useGameSound } from "features/courses/hook";
import { Score } from "features/courses/algorithm/game/scene/components";
import { StartBtn, ResetBtn, ZoomPanel } from "features/courses/components";
import { updateLevelAnswer, updateLevelProgresses } from "features/courses/api";
import {
  Failed,
  Success,
  ReadyStart,
  RecognitionRunFailed,
  RecognitionRunSuccess,
} from "./component";
import StageMaps from "./maps";
import { Control } from "./Control";
import * as action from "./animations";
import { GameScene } from "./GameScene";

export const MainScenePage = ({
  stage,
  step,
  isFinished,
  loading,
  reload,
  setReload,
  gameBgm,
  gameStart,
  setGameStart,
  workspace,
  setLoading,
}: {
  stage: number;
  step: number;
  isFinished: boolean;
  loading: boolean;
  reload: boolean;
  setReload: (reload: boolean) => void;
  gameBgm: Howl | null;
  gameStart: boolean;
  setGameStart: (start: boolean) => void;
  workspace: Blockly.WorkspaceSvg;
  setLoading: (loading: boolean) => void;
}) => {
  const play = usePlaySound();
  const { scale } = useScale();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gameSound = useGameSound();
  const [ready, setReady] = useState(false);
  const [gemScore, setGemScore] = useState(0);
  const [totalGem, setTotalGem] = useState(0);
  const [success, setSuccess] = useState(false);
  const stepMap = StageMaps[stage - 1][step - 1];
  const [gameOver, setGameOver] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [runType, setRunType] = useState(RunType.CLICK);
  const [scene, _setScene] = useState<Scene | null>(null);
  const sceneRef = useRef(scene);
  const setScene = (scene: Scene) => {
    sceneRef.current = scene;
    _setScene(scene);
  };
  const [capture, setCapture] = useState<string | null>(null);
  const summary = useSelector(
    (state: RootState) =>
      state.courses.courses[Course.ALGORITHM_TANGIBLE].summary
  );
  const course_id = useSelector(
    (state: RootState) =>
      state.courses.courses[Course.ALGORITHM_TANGIBLE].course_id
  );
  const [characters, _setCharacters] = useState<AbstractMesh[]>([]);
  const charactersRef = useRef(characters);
  const setCharacters = (characters: AbstractMesh[]) => {
    charactersRef.current = characters;
    _setCharacters(characters);
  };
  const user = useSelector((state: RootState) => state.user.appUser);
  const config = useSelector((state: RootState) => state.config.userConfig);

  useEffect(() => {
    if (gameOver) {
      const xml = Blockly.Xml.workspaceToDom(workspace);
      const answer = Blockly.Xml.domToText(xml);

      const now = dayjs().tz("Asia/Tokyo").format("YYYY-MM-DDTHH:mm:ssZ");

      console.log(`success: ${success}`);

      setGameStart(false);

      if (success) {
        setSuccessShow(true);

        gameSound.levelclearSound();
        gameSound.levelclearCheersSound();

        if (isFinished) {
          dispatch(
            actions.updateLevelAnswer({
              course: Course.ALGORITHM_TANGIBLE,
              stageId: stage,
              stepId: step,
              answer,
            })
          );
          updateLevelAnswer({
            course_id,
            uid: user.active.uid,
            stageId: stage,
            stepId: step,
            answer,
            answerAt: now,
            isFinished: true,
            parameter: { type: "tangible", capture },
          });
        } else {
          dispatch(
            actions.updateLevelProgresses({
              course: Course.ALGORITHM_TANGIBLE,
              stageId: stage,
              stepId: step,
              totalFinished: summary.finished + 1,
              answer,
              finishedAt: now,
            })
          );
          updateLevelProgresses({
            course_id,
            course: Course.ALGORITHM_TANGIBLE,
            uid: user.active.uid,
            stageId: stage,
            stepId: step,
            totalFinished: summary.finished + 1,
            answer,
            finishedAt: now,
            parameter:
              config.course_model === CourseModel.TANGIBLE
                ? { type: "tangible", capture }
                : {},
          });
        }
      } else {
        updateLevelAnswer({
          course_id,
          uid: user.active.uid,
          stageId: stage,
          stepId: step,
          answer,
          answerAt: now,
          isFinished: false,
          parameter: { type: "tangible", capture },
        });
        gameSound.failureSound();
      }
    }
  }, [gameOver]);

  useEffect(() => {
    gameSound.itemGetSound();
  }, [gemScore]);

  const handlePlusGemScore = (scene: Scene, totalGem: number) => {
    setGemScore((prevScore) => {
      const newScore = prevScore + 1;
      if (newScore === totalGem) {
        setSuccess(true);
      } else {
      }
      return newScore;
    });
  };

  const highlightBlock = (id: string, opt_state: boolean) => {
    if (!scene.isDisposed) {
      if (workspace.getBlockById(id)) {
        workspace.getBlockById(id).setHighlighted(opt_state);
      }
    }
  };

  const util = {
    sleep: sleep,
    setSuccess: setSuccess,
    setGameOver: setGameOver,
    highlightBlock: highlightBlock,
    action: {
      checkBtnColor: action.checkBtnColor,
      performMove: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          setSuccess(false);
          setGameOver(true);
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.moveSound();
          await action.performMove(
            scene,
            character,
            setSuccess,
            setGameOver,
            gameSound.moveSound,
            gameSound.fallingSound,
            false
          );
        }
      },
      performJump: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          setSuccess(false);
          setGameOver(true);
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.jumpSound();
          await action.performJump(
            scene,
            character,
            setSuccess,
            setGameOver,
            false
          );
        }
      },
      performTurnToLeft: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          setSuccess(false);
          setGameOver(true);
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.turnSound();
          await action.performTurnToLeft(scene, character, false);
        }
      },
      performTurnToRight: async (scene: Scene, character: AbstractMesh) => {
        if (!character) {
          if (scene && !scene.isDisposed) {
            scene.dispose();
          }
          setSuccess(false);
          setGameOver(true);
          return;
        }
        if (!scene.isDisposed && character.isEnabled()) {
          gameSound.turnSound();
          await action.performTurnToRight(scene, character, false);
        }
      },
    },
  };

  const handleRun = () => {
    setReady(false);
    gameBgm.play();
    workspace?.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
      block.setMovable(false);
      block.setEditable(false);
    });
    try {
      BlocklyJs.init(workspace);

      const code = BlocklyJs.workspaceToCode(workspace).replace(
        /function /g,
        "async $&"
      ); // safari maybe has some problems.

      const handleEvents = new Function(
        "scene",
        "character",
        "util",
        "gameSound",
        code
          ? code
          : `return () => {
              util.setGameOver(true);
            }
        `
      );

      let character: AbstractMesh;
      workspace
        .getTopBlocks(true)
        .filter((block) => block.isEnabled())
        .forEach((block) => {
          if (block.type.includes("character_event")) {
            const characterName = block.getFieldValue("CHARACTER");
            character = charactersRef.current
              .filter((character) => character.name === characterName)
              .shift();
          }
        });

      if (character) {
        handleEvents(sceneRef.current, character, util, gameSound)();
      } else {
        setSuccess(false);
        setGameOver(true);
      }
    } catch (error) {
      console.error(error);
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setSuccess(false);
      setGameOver(true);
    }
  };

  const handleClickStart = () => {
    const character = charactersRef.current[0];
    if (character) {
      btnDelay(() => {
        setReady(true);
        gameBgm.stop();
        setGameStart(true);
        setRunType(RunType.CLICK);
        gameSound.startCountdownSound();
        setTimeout(() => handleRun(), 3000);
      });
    }
  };

  const handleStart = () => {
    const character = charactersRef.current[0];
    if (character) {
      setReady(true);
      gameBgm.stop();
      setRunType(RunType.RECOGNITION);
      gameSound.startCountdownSound();
      setTimeout(() => {
        setGameStart(true);

        const canvas = (document.getElementById(
          "local-stream-canvas"
        ) as unknown) as HTMLCanvasElement;
        const dataUrl = canvas.toDataURL("image/jpeg", 0.5);
        const fileBlob = dataURLToBlob(dataUrl);

        const uuid = uuidv4();
        const filename = `tangible/${uuid}.png`;

        uploadImageFile({
          is_sync: false,
          filename,
          fileBlob,
        }).then(() => setCapture(`${Constants.assetHost}/${filename}`));

        handleRun();
      }, 3000);
    }
  };

  const handleGameOver = () => {
    if (success) {
      console.log("game over success.");
      gameBgm.stop();
      if (step < 7) {
        navigate(
          `/courses/${Course.ALGORITHM_TANGIBLE}/stage/${stage}/step/${
            step + 1
          }`
        );
      } else {
        navigate(`/courses`);
      }
    } else {
      console.log("game over failed.");
      handleReset();
    }
  };

  const handleReset = () => {
    play();
    btnDelay(() => {
      if (scene && !scene.isDisposed) {
        scene.dispose();
      }
      setGemScore(0);
      setLoading(true);
      setSuccess(false);
      setGameOver(false);
      setReload(!reload);
      setGameStart(false);
      setSuccessShow(false);
      workspace.getAllBlocks(false).forEach((block: Blockly.BlockSvg) => {
        block.setMovable(true);
        block.setHighlighted(false);
        block.setEditable(true);
      });
    });
  };

  const handleBack = () => {
    play();
    btnDelay(() => {
      navigate(`/courses/${Course.ALGORITHM_TANGIBLE}/stage/${stage}`);
    });
  };

  return (
    <>
      <ZoomPanel scene={sceneRef.current} />

      <ReadyStart open={ready} />

      {gameOver &&
        !success &&
        (runType === RunType.CLICK ? (
          <Failed scale={scale} reset={handleReset} />
        ) : (
          <RecognitionRunFailed reset={handleReset} />
        ))}

      {successShow &&
        (runType === RunType.CLICK ? (
          <Success scale={scale} back={handleBack} reset={handleReset} />
        ) : (
          <RecognitionRunSuccess />
        ))}

      {workspace && scene && characters.length > 0 && (
        <Control
          ready={ready}
          gameStart={gameStart}
          gameOver={gameOver}
          workspace={workspace}
          handleStart={handleStart}
          handleGameOver={handleGameOver}
        />
      )}

      <div className="flex-col-view items-center w-full h-full pointer-events-none">
        <div className="flex-col-center w-full h-full pointer-events-none">
          <GameScene
            map={stepMap}
            reload={reload}
            handler={{
              setLoading,
              setScene,
              setCharacters,
              setTotalGem,
              handlePlusGemScore,
            }}
          />

          <div
            style={{ height: 56 * scale }}
            className="flex-row-el absolute top-0"
          >
            <div
              className="flex-row-view h-[56px] origin-top"
              style={{ transform: `scale(${scale})` }}
            >
              <Score totalGem={totalGem} gemScore={gemScore} />
            </div>
          </div>
        </div>

        {!gameStart ? (
          <StartBtn
            scale={scale}
            disabled={
              charactersRef.current.filter((character) => character.isEnabled())
                .length === 0
            }
            start={handleClickStart}
          />
        ) : (
          <ResetBtn scale={scale} reset={handleReset} />
        )}
      </div>
    </>
  );
};
